<template>
  <b-card title="Lista de jogos" id="cardo">
    <b-table
      :items="searchedResults"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(provider)="data">
        {{data.item.provider.name}}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          icon="EditIcon"
          class="ml-2 text-success pointer"
          @click="edit(data.item.id)"
        />
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import JsonExcel from "vue-json-excel";

import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props:['searchedResults', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {
      fields: ['id',{key:'group_code', label:'Wallet'}, {key:'code',label:'Código'}, { key: 'actions', label: 'Ações' }],
    }
  },
  apollo:{
    
  },
  watch:{
    searchedResults: function(){
      // this.users = this.searchedResults
      // _.each(this.searchedResults, this.formatSave);
    },
    
  },
  methods:{
    formatSave(e){
      // let toSave = {
      //   "id" : e.id,
      //   "username": e.username,
      //   "status": e.status,
      //   "register": e.created_at,
      //   "invite_code": e.documents.filter(d => d.type == "INVITECODE")[0]?.value,
      //   "phone": e.documents.filter(d => d.type == "PHONE")[0]?.value,
      //   "nickname": e.documents.filter(d => d.type == "NICKNAME")[0]?.value,
      //   "fullname": e.documents.filter(d => d.type == "FULLNAME")[0]?.value
      // }
      // this.toExport.push(toSave);
    },
    cellDecoupe(value, key, item){
      let obj = item.documents.filter(d => d.type == key.toUpperCase())
      return obj.length > 0 ? obj[0].value : '-'
    },
    checkContains(index){
     return _.contains(this.contextActions,index); 
    },
    edit(id){
      this.$router.push('/game-group-single/'+id);
    }
    
  },
  created(){
    // if(this.addInfo != null && this.addInfo.key == 'related'){
    //   this.fields = this.fields.slice(0,-1)
    //   this.fields.push({key:'relation_name', label:'Relação', formatter:'relationName'})
    //   this.fields.push({ key: 'actions', label: 'Ações' })
    // }
    // if(this.customFields != null){
    //   this.fields = this.customFields;
    // }
    // _.each(this.searchedResults, this.formatSave);
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>