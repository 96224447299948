<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um grupo 🔍">
        <b-card-text>No campo abaixo busque por nome:</b-card-text>
        <b-form-input
          v-model="searchQuery"
          icon-no-border
          placeholder="Digite e pressione ENTER"
          class="input-rounded-full"
          icon="icon-search"
          id="live-query"
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          @keyup.native="searchGroup()"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card>
      <general-groups-list
        :searched-results="queryResult"
        :context-actions="['details']"
      />
      <nav aria-label="...">
        <ul class="pagination">
          <li
            v-if="pageInfo.currentPage - 1 > 0"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(-1)"
            >Anterior</span>
          </li>
          <li class="page-item active">
            <span class="page-link">
              {{ pageInfo.currentPage }}
            </span>
          </li>
          <li
            v-if="pageInfo.currentPage < pageInfo.lastPage"
            class="page-item"
          >
            <span
              class="page-link pointer"
              @click="paginate(+1)"
            >Proxima</span>
          </li>
        </ul>
      </nav>

    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/games/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralGroupsList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation,

  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      pageToSearch: 1,
      pageInfo: {},
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    this.searchUser()
  },
  methods: {
    searchUser() {
      if (this.searchQuery == '' || ! this.docState) {
        this.$apollo.query({
          query: gql`query{
            Groups(mode:"PUBLIC"){
              id,
              code,
              group_code,
            }
          }`,
          client: 'gameClient',
        }).then(data => {
          console.log(data);
          this.queryResult = data.data.Groups;
        }).catch(error => {
          console.error(error)
        })
      }else{
        this.queryResult = this.mapResult.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
    },
    paginate(eV) {
      if (this.pageToSearch == 1) {
        this.pageToSearch = 2
      } else {
        this.pageToSearch += eV
      }
      this.searchUser()
    },
  },
}
</script>

<style>

</style>
